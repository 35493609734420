import '../layout';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

function serviceSwiper() {
    const swiper = new Swiper('.service__swiper', {
        modules: [Navigation],
        slidesPerView: 1,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 20,
            }
        }
    })
}

window.addEventListener('DOMContentLoaded', () => {
    serviceSwiper()
})
