import * as bootstrap from 'bootstrap'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
ScrollTrigger.clearScrollMemory('manual')
window.history.scrollRestoration = 'manual'

let gsapSmoothScrollObj

function gsapInitScrollSmoother() {
    if (ScrollTrigger.isTouch !== 1) {
        gsapSmoothScrollObj = ScrollSmoother.create({
            smooth: 0.8,
            effects: true,
        });
    }
}

function addClassNavbar() {
    window.addEventListener('scroll', () => {
        if (window.scrollY > 200) {
            document.querySelector('.custom-navbar').classList.add('scrolled')
        } else {
            document.querySelector('.custom-navbar').classList.remove('scrolled')
        }
    })
}

function stopSmoothScrollOnOffcanvas() {
    const scrollbarWidth = (window.innerWidth - document.body.clientWidth) + 'px';
    const customNavbar = document.querySelector('.custom-navbar')

    document.addEventListener('show.bs.offcanvas', () => {
        gsapSmoothScrollObj.kill()
        ScrollTrigger.killAll()
        if (customNavbar) {
            customNavbar.style.right = scrollbarWidth
        }
    })
    
    document.addEventListener('hidden.bs.offcanvas', () => {
        gsapInitScrollSmoother()
        if (customNavbar) {
            customNavbar.style.right = null
        }
    })
}

function fadeUp() {
    const fadeEl = gsap.utils.toArray('[tmdr-anim="fade-up"]')

    fadeEl.forEach(fe => {
        gsap.to(fe, {
            autoAlpha: 1,
            duration: 1,
            y: '0',
            ease: 'power4.out',
            stagger: 1,
            scrollTrigger: {
                trigger: fe,
                start: 'top bottom-=100',
                // markers: true,
            }
        })
    });
}

function hideTransition() {
    gsap.to('.page-transition', {
        duration: 0.3,
        autoAlpha: 0,
    })
}

gsapInitScrollSmoother()

window.addEventListener('DOMContentLoaded', () => {
    hideTransition()
    addClassNavbar()
    stopSmoothScrollOnOffcanvas()
    fadeUp()
})

export { gsapSmoothScrollObj }

export { ScrollTrigger }